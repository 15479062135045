export enum StorageKey {
  USER = 'User',
  USER_ID = 'UserId',
  USER_PREFERENCES = 'UserPreferences',
  QUERY_PARAMS = 'QueryParams',
  ROLE = 'Role',

  // Para Session
  SESSION_MODELS = 'Models',
  SESSION_CATEGORIES = 'Categories',
  SESSION_TYPES = 'Types',
  SESSION_ZONES = 'Zones',
  SESSION_POSTALCODES = 'PostalCodes',

}
